@import "~@ui/styles/variables";
@import "~@ui/styles/tools";
@import "@app/styles/variables";

@mixin medium-breakpoint {
    @media screen and (max-width: 1400px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 900px) {
        @content;
    }
}

.block {
    background-color: $color-page-background;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    gap: 0;
}

.block--header, .block--footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 28px;
    gap: 16px;

    @include small-breakpoint {
        padding: 20px 16px;
    }
}

.block--divider {
    height: 1px;
    width: 100%;
    background-color: $color-grey;
}

.service--logo {
    height: 44px;
    width: 44px;
    padding: 6px;

    position: relative;
    border-radius: 50%;
    overflow: hidden;
    background-color: $color-off-white;
    border: 1px solid #E2E3E4;

    > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.service--name {
    color: $color-title-active;
    font-size: 26px;
    line-height: 32px;
    font-weight: 600;

    @include small-breakpoint {
        font-size: 20px;
        line-height: 24px;
    }
}
